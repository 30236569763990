import without from 'lodash.without'
import { useEffect, useState } from 'react'

import { useBottomSheet } from '@context/BottomSheetContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import IconCheckedCircle from '@icons/IconCheckedCircle.svg'
import IconCircle from '@icons/IconCircle.svg'
import { FilterableMediaTypes, MediaType } from '@models/MediaTypes'
import { MediaTypeIcons, MediaTypeTitles } from '@pages/node/components/MediaTypeConfig'
import { selectMediaTypes, setMediaTypes } from '@redux/slices/appSlice'
import { clearAllItemsExceptCurrent, setIsChangingFeed, setPageNumber } from '@redux/slices/feedSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'

const MobileFeedFilters = () => {
  const mediaTypes = useAppSelector(selectMediaTypes)
  const [selectedMediaTypes, setSelectedMediaTypes] = useState<MediaType[]>(mediaTypes)
  const [isChanged, setIsChanged] = useState(false)
  const dispatch = useAppDispatch()
  const trackClick = useTrackClick()
  const { closeBottomSheet } = useBottomSheet()

  useEffect(() => {
    setIsChanged(JSON.stringify(selectedMediaTypes) !== JSON.stringify(mediaTypes))
  }, [selectedMediaTypes, mediaTypes])

  const handleCheckboxChange = (mediaType: MediaType) => {
    const filteredMediaTypes = without(selectedMediaTypes, 'WEBSITE')

    // Prevent unchecking the last remaining media type
    if (filteredMediaTypes.length === 1 && filteredMediaTypes.includes(mediaType)) {
      return
    }
    trackClick('feedFilter', { source: 'mobile-feed-filters', title: mediaType })
    setSelectedMediaTypes(prevTypes => {
      if (prevTypes.includes(mediaType)) return prevTypes.filter(mt => mt !== mediaType)
      return [...prevTypes, mediaType]
    })
  }

  const handleSave = () => {
    if (isChanged) {
      dispatch(setIsChangingFeed(true))
      dispatch(clearAllItemsExceptCurrent(undefined))
      dispatch(setMediaTypes(selectedMediaTypes))
      dispatch(setPageNumber(1))
      closeBottomSheet()
    }
  }

  return (
    <div className="flex h-full flex-col p-4">
      <div className="mb-4 w-full text-center text-lg font-bold">Filters</div>
      <div className="flex flex-col space-y-4 px-4">
        {FilterableMediaTypes.map(mediaType => (
          <label key={mediaType} className="flex items-center justify-between space-x-2" htmlFor={`type-${mediaType}`}>
            <div className="flex items-center">
              <input
                className="peer hidden"
                type="checkbox"
                id={`type-${mediaType}`}
                name={mediaType}
                value={mediaType}
                checked={selectedMediaTypes.includes(mediaType)}
                onChange={() => handleCheckboxChange(mediaType)}
              />
              {MediaTypeIcons[mediaType]({})}
              <div className="ml-4 text-lg font-semibold">{MediaTypeTitles[mediaType]}</div>
            </div>
            <div className="size-7">
              {selectedMediaTypes.includes(mediaType) ? <IconCheckedCircle /> : <IconCircle />}
            </div>
          </label>
        ))}
      </div>

      <div className="mt-6 flex w-full justify-center">
        <button
          className="btn btn-primary bg-accent px-8 py-2 text-white disabled:pointer-events-none disabled:saturate-0"
          onClick={handleSave}
          disabled={!isChanged}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default MobileFeedFilters
