import { FormEvent, FormEventHandler, ReactElement } from 'react'

import Modal, { ChildModalProps } from '@common/Modal'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import IconCheck from '@icons/IconChecked.svg'
import { FilterableMediaTypes, MediaType } from '@models/MediaTypes'
import { selectMediaTypes, setMediaTypes } from '@redux/slices/appSlice'
import { setCurrentFeedItemKey, setInstanceId, setIsChangingFeed, setPageNumber } from '@redux/slices/feedSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { MediaTypeIcons, MediaTypeTitles } from './MediaTypeConfig'

export const useFilterSubmit = () => {
  const dispatch = useAppDispatch()
  return (event: FormEvent<HTMLFormElement>) => {
    const selectedMediaTypes = Array.from(new FormData(event.currentTarget).values()) as MediaType[]
    const _instanceId = Date.now().toString()
    dispatch(setIsChangingFeed(true))
    dispatch(setMediaTypes(selectedMediaTypes))
    dispatch(setCurrentFeedItemKey(null))
    dispatch(setInstanceId(_instanceId))
    dispatch(setPageNumber(1))
  }
}

export const FeedFiltersForm = ({
  children,
  className,
  orientation = 'horizontal',
  shouldSubmitOnChange = false,
  onSubmit,
  onChange,
}: {
  children: (mediaType: MediaType) => ReactElement
  className?: string
  orientation?: 'horizontal' | 'vertical'
  shouldSubmitOnChange?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
  onChange?: FormEventHandler<HTMLFormElement>
}) => {
  const mediaTypes = useAppSelector(selectMediaTypes)
  const trackClick = useTrackClick()

  return (
    <form className={className} onSubmit={onSubmit} onChange={onChange}>
      <div className={`flex ${orientation === 'horizontal' ? 'space-x-1.5' : 'flex-col space-y-4'}`}>
        {FilterableMediaTypes.map(mediaType => (
          <label
            key={mediaType}
            className="relative flex cursor-pointer items-center justify-between"
            htmlFor={`type-${mediaType}`}
          >
            <input
              className="peer hidden"
              type="checkbox"
              id={`type-${mediaType}`}
              name={mediaType}
              value={mediaType}
              {...(shouldSubmitOnChange && { checked: mediaTypes.includes(mediaType), readOnly: true })}
              {...(!shouldSubmitOnChange && { defaultChecked: mediaTypes.includes(mediaType) })}
              onClick={() => trackClick('feedFilter', { source: 'feed-filters-form', title: mediaType })}
            />
            {children(mediaType)}
          </label>
        ))}
      </div>
      {onSubmit && (
        <input
          type="submit"
          className="btn cursor-pointer self-center bg-orange-500 text-white disabled:cursor-default disabled:bg-black/20 disabled:text-black/40 disabled:hover:scale-100"
          value="Apply"
        />
      )}
    </form>
  )
}

export const FeedFilters = () => {
  const handleSubmit = useFilterSubmit()

  return (
    <div className="flex space-x-1.5">
      <div className="mr-2 hidden self-center text-xs font-medium tracking-wider opacity-70 sm:block">FILTERS</div>
      <FeedFiltersForm className="flex" onChange={handleSubmit} shouldSubmitOnChange={true}>
        {mediaType => (
          <div className="btn btn-square group relative flex size-10 cursor-pointer items-center justify-center rounded-full bg-transparent p-2 text-primary/35 transition-all hover:bg-primary/90 hover:text-primary peer-checked:text-primary">
            {MediaTypeIcons[mediaType]({})}
            <span className="absolute top-full translate-y-0 scale-75 rounded bg-tooltip px-1 py-0.5 text-xs font-semibold text-tooltip opacity-0 transition-all duration-150 group-hover:translate-y-2 group-hover:scale-100 group-hover:opacity-100">
              {MediaTypeTitles[mediaType]}
            </span>
          </div>
        )}
      </FeedFiltersForm>
    </div>
  )
}

export const FeedFiltersModal = ({ isVisible, onClose }: ChildModalProps) => {
  const handleSubmit = useFilterSubmit()

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <div className="relative z-10 w-80 rounded-xl bg-menu px-10 py-12 text-menu sm:w-[30rem]">
        <div className="flex flex-col space-y-4 font-semibold">
          <div className="flex flex-col space-y-6">
            <div className="text-[36px]">Filters</div>
            <FeedFiltersForm
              className="flex flex-col space-y-6"
              onSubmit={event => {
                event.preventDefault()
                handleSubmit(event)
                onClose(false)
              }}
              orientation="vertical"
            >
              {mediaType => (
                <>
                  <div className="flex">
                    {MediaTypeIcons[mediaType]({})} <span className="ml-2">{MediaTypeTitles[mediaType]}</span>
                  </div>
                  <div className="size-9 rounded-full border border-black/10 hover:scale-105 peer-checked:border-none peer-checked:bg-black/10" />
                  <IconCheck className="invisible absolute right-2 top-2 size-5 peer-checked:visible" />
                </>
              )}
            </FeedFiltersForm>
          </div>
        </div>
      </div>
    </Modal>
  )
}
