import { useRouter } from 'next/router'
import { MouseEventHandler, ReactNode, useCallback, useEffect } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useSegmentTrack } from '@hooks/analytics/useSegmentAnalytics'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import { useDeeplinkInfo } from '@hooks/useDeeplinkInfo'
import {
  setCopyVariant,
  setIsLoginModalOpen,
  setReturnPathOnClose,
  setReturnUrl,
} from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch } from '@redux/store/store'

interface LoginButtonProps {
  children: ReactNode
  copyVariant?: 'default' | 'add' | 'login' | 'personalize' | 'conversation'
  className?: string
  onClickIfLoggedIn?: MouseEventHandler<HTMLButtonElement>
  deeplinkUrl?: string
  showModalOnNative?: boolean
  forceShowModal?: boolean
  setForceShowModal?: (forceShowModal: boolean) => void
  source?: string
}

const LoginButton = ({
  children,
  copyVariant = 'default',
  deeplinkUrl,
  className,
  onClickIfLoggedIn,
  showModalOnNative = false,
  forceShowModal = false,
  setForceShowModal,
  source,
}: LoginButtonProps) => {
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useCurrentUserContext()
  const router = useRouter()
  const deeplinkInfo = useDeeplinkInfo(deeplinkUrl)

  const track = useSegmentTrack()
  const trackClick = useTrackClick()
  const showModalOrFollowLink = useCallback(() => {
    if (deeplinkInfo?.deeplinkUrl && !showModalOnNative) {
      track('app-download', { platform: deeplinkInfo.platform })
      location.assign(deeplinkInfo.deeplinkUrl)
      return
    }
    trackClick('login', { source })
    const currentPath = router.asPath

    // Change the state to visible
    dispatch(setCopyVariant(copyVariant))
    dispatch(setReturnUrl(deeplinkUrl))
    dispatch(setReturnPathOnClose(currentPath))
    dispatch(setIsLoginModalOpen(true))
    return true
  }, [
    deeplinkInfo?.deeplinkUrl,
    deeplinkInfo?.platform,
    showModalOnNative,
    track,
    trackClick,
    dispatch,
    copyVariant,
    deeplinkUrl,
    router.asPath,
    source,
  ])

  useEffect(() => {
    if (forceShowModal) {
      showModalOrFollowLink()
      setForceShowModal?.(false)
    }
  }, [forceShowModal, setForceShowModal, showModalOrFollowLink])

  return (
    <button onClick={isLoggedIn && onClickIfLoggedIn ? onClickIfLoggedIn : showModalOrFollowLink} className={className}>
      {children}
    </button>
  )
}

export default LoginButton
