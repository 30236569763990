import axios from 'axios'
import pick from 'lodash.pick'
import { GetServerSideProps } from 'next'
import Image from 'next/image'
import { useEffect } from 'react'

import { useTheme } from '@context/ThemeContext'
import LoginButton from '@desktop/login/LoginButton'
import { MetaTags } from '@desktop/MetaTags'
import { useIsDesktop } from '@hooks/useIsDesktop'
import { useIsSmallScreen } from '@hooks/useIsSmallScreen'
import IconMixLogo from '@icons/IconMixLogo.svg'
import logger from '@lib/logger'
import { setHeaderState } from '@redux/slices/headerSlice'
import { AppDispatch, wrapper } from '@redux/store/store'
import MixAPI from '@services/MixAPI'
import { ContestHeroVideo } from './components/ContestHeroVideo'
import { ContestScrollingPanel } from './components/ContestScrollingPanel'
import { ContestSubmitModal, useContestSubmitModal } from './components/ContestSubmitModal'

export const CONTEST_CATEGORY = 'Breakdancing'
export const DEADLINE = 'October 31st'

const ContestPageHeader = ({ onModalShow }: { onModalShow: () => void }) => {
  return (
    <div className="fixed top-0 z-30 flex h-40 w-full items-center justify-end bg-gradient-to-b from-black to-transparent p-8">
      <div className="absolute left-1/2 top-0 flex h-full -translate-x-1/2 pt-3">
        <Image src="/logo_white.svg" alt="Mix" height={40} width={120} />
      </div>
      <LoginButton
        className="btn btn-accent w-56 text-[17px] font-medium"
        onClickIfLoggedIn={onModalShow}
        deeplinkUrl="/contest/submit"
        source="contest-submit-header"
      >
        Submit a video
      </LoginButton>
    </div>
  )
}

const ContestPageHeaderMobile = () => {
  return (
    <div className="sticky top-0 z-30 mt-20 flex w-full justify-center">
      <IconMixLogo className="size-11" />
    </div>
  )
}

const ContestSubmitFooter = ({ onModalShow }: { onModalShow: () => void }) => (
  <div className="fixed bottom-0 left-1/2 z-30 flex h-48 w-full max-w-3xl -translate-x-1/2 flex-col justify-end bg-gradient-to-b from-transparent via-black/85 to-black p-8 sm:h-60 sm:p-12 sm:pb-16">
    <LoginButton
      className="btn btn-accent h-14 font-medium sm:h-24 sm:text-3xl"
      onClickIfLoggedIn={onModalShow}
      deeplinkUrl="/contest/submit"
      source="contest-submit-footer"
    >
      Submit a video
    </LoginButton>
  </div>
)

const IconArrow = () => (
  <svg className="size-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 4L13 16.17L16.59 12.59L18 14L12 20L6 14L7.41 12.59L11 16.17L11 4H13Z" fill="white" />
  </svg>
)

const ContestTextMobile = () => (
  <>
    <div className="text-[13px] opacity-70">Contest Topic</div>
    <div className="bg-accent bg-clip-text">
      <div className="bg-gradient-to-b from-[#F9A01A] to-[#CF3789] bg-clip-text text-[28px] font-bold text-white/60">
        {CONTEST_CATEGORY}
      </div>
    </div>
  </>
)

const ContestTextDesktop = () => (
  <>
    <div className="text-[13px] font-medium">CONTEST TOPIC</div>
    <div className="flex text-[40px] font-bold leading-[110%] text-white">
      <span className="block scale-x-75 italic">#</span>
      {CONTEST_CATEGORY}
    </div>
  </>
)

const ContestHero = () => {
  const isSmallScreen = useIsSmallScreen()
  return (
    <div className="sticky -top-72 flex flex-col items-center sm:-top-96">
      <div className="relative h-[1000px] w-full sm:h-[1450px]">
        <ContestHeroVideo />
        <div className="absolute top-0 flex w-full flex-col items-center space-y-10 pt-16 text-center leading-tight sm:space-y-12 sm:leading-none">
          <h1 className="text-[32px] font-bold leading-[110%] sm:text-[64px] sm:leading-none sm:tracking-tight">
            Get rewarded for
            <br /> finding gems
          </h1>
          <h2 className="text-[21px] font-medium tracking-[-.5px] text-accent sm:text-[32px] sm:tracking-[-1px]">
            Win $500 and become a<br /> top curator on Mix.
          </h2>
          <IconArrow />
        </div>
        <div className="absolute bottom-64 flex w-full flex-col items-center sm:bottom-96">
          {isSmallScreen ? <ContestTextMobile /> : <ContestTextDesktop />}
        </div>
      </div>
    </div>
  )
}

const ContestPage = () => {
  const isSmallScreen = useIsSmallScreen()
  const isDesktop = useIsDesktop()
  const { isModalVisible, onModalClose, onModalShow } = useContestSubmitModal()
  const { setForcedTheme } = useTheme()

  useEffect(() => {
    setForcedTheme('dark')
    return () => setForcedTheme(undefined)
  }, [setForcedTheme])

  return (
    <>
      <style jsx global>
        {`
          body {
            background-color: black;
            color: white;
          }
        `}
      </style>
      <MetaTags
        title={`${isDesktop ? 'Mix - ' : ''}Curation Contest`}
        image="https://assets.mix.com/static/contests/2024/breakdancing/og_image.jpg"
      />
      {isSmallScreen ? <ContestPageHeaderMobile /> : <ContestPageHeader onModalShow={onModalShow} />}
      <div className="relative w-full sm:mt-20">
        {isSmallScreen && <ContestSubmitFooter onModalShow={onModalShow} />}
        <ContestHero />
        <ContestScrollingPanel />
      </div>
      <ContestSubmitModal isVisible={isModalVisible} onClose={onModalClose} />
    </>
  )
}

export default ContestPage

export const getServerSideProps: GetServerSideProps = wrapper.getServerSideProps(store => async context => {
  // Only allow US submissions
  const loggedHeaders = pick(context.req.headers, ['x-forwarded-for', 'x-real-ip', 'referer', 'user-agent'])
  const reqIP = store.getState().app.reqIP
  if (!reqIP) {
    logger.warn('[submission-contest] Request IP not found', loggedHeaders)
    return {
      notFound: true,
    }
  }
  const { data } = await axios.request(MixAPI.util.ipInfo(reqIP))
  if (data.country !== 'US' && process.env.NODE_ENV === 'production') {
    logger.warn('[submission-contest] Request IP not in US', data, loggedHeaders)
    return {
      notFound: true,
    }
  }

  const dispatch = store.dispatch as AppDispatch
  setHeaderState({
    dispatch,
    isDisabled: true,
    isTitleEnabled: false,
    isSharingEnabled: false,
    isUrlActionsEnabled: false,
    isGridActionsEnabled: false,
    isFiltersEnabled: false,
  })
  return {
    props: {},
  }
})
